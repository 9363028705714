import { Grid, Typography } from '@mui/material'
import React from 'react'
import NoAccountsIcon from '@mui/icons-material/NoAccounts';


const UnauthorizedUser = () => {

    return (<>
        <Grid container justifyContent={'center'} alignItems="center" sx={{ minHeight: 300 }} >

            <Grid m={3} container direction="column" alignItems="center" >
                <NoAccountsIcon />
                <Typography>Sorry, this link is no longer valid. Please contact your account administrator.</Typography></Grid>
        </Grid>

    </>)
}

export default UnauthorizedUser