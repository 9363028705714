import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Paper } from '@mui/material'
import TrackingComponent from '../../components/TrackingComponent';
import { useNavigate } from 'react-router-dom';
import useServices from '../../services/useServices';
import moment from 'moment';
import axios from 'axios';
import { getSessionData, setSessionData } from '../../utils/utils';
const steps = [
    {
        label: "Request Received",
        status: "requestTime",
        class: "requested",
        description: null
    },
    {
        label: "Driver Assigned",
        status: "acceptedTime",
        class: "accepted",
        description: null

    },
    {
        label: "Driver Enroute",
        status: "enrouteTime",
        class: "onroute",
        description: null
    },
    {
        label: "Driver Arrived",
        status: "arrivedTime",
        class: "arrived",
        description: null
    }
];
const TrackOrder = () => {
    const navigate = useNavigate();
    const { get } = useServices()
    const [showTrackingDetailsID, setShowTrackingDetailsID] = useState()
    const [requestDetails, setRequestDetails] = useState({})
    const [invoiceStatus, setInvoiceStatus] = useState('')
    const [cancelReason, setCancelReason] = useState('')
    const [elapsedData, setElapsedData] = useState({})
    const [initialRespData, setInitialRespData] = useState({})
    const [trackingData, setTrackingData] = useState(JSON.parse(JSON.stringify(steps)))
    const [isLoading, setIsLoading] = useState(false)
    var ourRequest = useRef(null)
    const gotoHome = () => {
        navigate("/")
    }
    const serviceOrders = getSessionData('serviceOrders')
    const serviceRequestNumbers = serviceOrders ? JSON.parse(serviceOrders) : []
    console.log(serviceRequestNumbers)
    const clearFromTrack = () => {

        const updatedids = serviceRequestNumbers.filter(item => item !== showTrackingDetailsID)
        setSessionData(updatedids, 'serviceOrders')
        window.location.reload()
    }
    // const serviceRequestNumbers = ['220425004', '220425003']
    const getTrackingDetails = async () => {
        try {
            console.log(ourRequest.current)
            if (ourRequest.current) {
                ourRequest.current.cancel()
            }
            ourRequest.current = axios.CancelToken.source()
            // setTimeout(async ()=>{
            const resp = await get('invoice/directCall/' + showTrackingDetailsID, ourRequest.current.token)
            console.log(resp)
            elapsedData[showTrackingDetailsID] = resp.data.resp.elapsedTime

            setRequestDetails(resp.data.resp)
            setElapsedData({ ...elapsedData })
            // },5000)

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const showTrackingDetails = (id) => {

        setTrackingData(JSON.parse(JSON.stringify(steps)))
        if (id === showTrackingDetailsID) {
            setShowTrackingDetailsID(0)
        } else {
            setIsLoading(true)
            setShowTrackingDetailsID(id)
        }
    }
    const setTrackingDetailsData = () => {
        if (requestDetails.callStatus !== 'Cancel') {
            trackingData.map(item => {
                const dateTime = requestDetails[item.status]
                item.description = dateTime ? 'At ' + moment(dateTime).format('HH:mm:ss MM/DD/YYYY') : dateTime
                return item
            })
            setTrackingData(JSON.parse(JSON.stringify(trackingData)))
            setCancelReason('')
        } else {
            setCancelReason(requestDetails.reason)
        }
        setInvoiceStatus(requestDetails.callStatus)
        setIsLoading(false)
    }
    const fetchInitialData = async () => {
        try {
            const ordersData = serviceRequestNumbers.map(item => get('invoice/directCall/' + item))
            const resultArray = await Promise.allSettled(ordersData)
            const elapsedObj = {}

            resultArray.forEach((item, i) => {
                if (item.status === "fulfilled") {
                    console.log(item)
                    const id = serviceRequestNumbers[i]
                    elapsedObj[id] = item.value.data?.resp?.elapsedTime
                    initialRespData[id] = item.value.data?.resp
                }
            })
            setElapsedData({ ...elapsedObj })
            setInitialRespData({ ...initialRespData })
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (showTrackingDetailsID)
            getTrackingDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTrackingDetailsID])
    useEffect(() => {
        setTrackingDetailsData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestDetails])
    useEffect(() => {
        const userID = getSessionData("userID")
        if (!userID) {
            navigate("/unauthorizedUser")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Box sx={{ flexGrow: 1, paddingLeft: 1, paddingRight: 1, paddingTop: 1, backgroundColor: "#f5f5f5" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} textAlign="end">
                        <Button onClick={gotoHome}>Home</Button>
                    </Grid>
                    {Object.keys(initialRespData).length > 0 && serviceRequestNumbers.map(item => (

                        <Grid item xs={12} key={item} >
                            {!initialRespData[item]?.hookedTime &&
                                <>
                                    <Paper sx={{ width: "100%" }} onClick={() => showTrackingDetails(item)}>
                                        <Grid sx={{ border: "1px solid #80808066", padding: "10px 10px" }} container item xs={12} flexDirection='row'>
                                            <Grid item xs={4} sx={{ overflowWrap: "break-word" }}>
                                                #{item}
                                            </Grid>
                                            <Grid container item xs={8} justifyContent="flex-end">
                                                Time Elapsed : {elapsedData[item]?.split(':')[0] || " __"} min {elapsedData[item]?.split(':')[1] || " __"} sec
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    {showTrackingDetailsID === item &&
                                        <Box ml={2} mt={2}>
                                            <TrackingComponent clearFromTrack={clearFromTrack} hookedTime={requestDetails?.hookedTime} cancelReason={cancelReason} invoiceStatus={invoiceStatus} steps={trackingData} isLoading={isLoading} />
                                        </Box>
                                    }
                                </>
                            }
                        </Grid>

                    )
                    )}
                </Grid>
            </Box>
        </>
    )
}

export default TrackOrder