import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import useServices from '../../services/useServices';
import moment from 'moment';
import axios from 'axios';
import makeStyles from '@mui/styles/makeStyles';

import { getSessionData } from '../../utils/utils';
const steps = [
    {
        label: "Request Sent",
        status: "requestTime",
        class: "requested",
        description: null
    },
    {
        label: "Request Accepted",
        status: "acceptedTime",
        class: "accepted",
        description: null

    },
    {
        label: "Onroute",
        status: "enrouteTime",
        class: "onroute",
        description: null
    },
    {
        label: "Arrived",
        status: "arrivedTime",
        class: "arrived",
        description: null
    }
];

const homePageStyles = makeStyles({
    "labelName": {
        fontWeight: "600",
        marginBottom: "8px"
    },
    valueStyle: {
        marginBottom: "15px !important"
    },

})

const Home = () => {
    const navigate = useNavigate()
    const classes = homePageStyles()
    const { get } = useServices()
    const [showTrackingDetailsID, setShowTrackingDetailsID] = useState()
    const [requestDetails, setRequestDetails] = useState({})
    const [trackingData, setTrackingData] = useState(JSON.parse(JSON.stringify(steps)))
    const [isLoading, setIsLoading] = useState(false)
    const [userData, setUserData] = useState({})

    var ourRequest = useRef(null)
    const gotoNewRequest = () => {
        navigate("/newrequest")
    }
    const serviceOrders = getSessionData('serviceOrders')
    const serviceRequestNumbers = serviceOrders ? JSON.parse(serviceOrders) : []
    // const serviceRequestNumbers = ['220425004', '220425003']
    const getTrackingDetails = async () => {
        try {
            console.log(ourRequest.current)
            if (ourRequest.current) {
                ourRequest.current.cancel()
            }
            ourRequest.current = axios.CancelToken.source()
            // setTimeout(async ()=>{
            const resp = await get('invoice/directCall/' + showTrackingDetailsID, ourRequest.current.token)
            console.log(resp)
            setRequestDetails(resp.data.resp)
            // },5000)

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const showTrackingDetails = (id) => {

        setTrackingData(JSON.parse(JSON.stringify(steps)))
        if (id === showTrackingDetailsID) {
            setShowTrackingDetailsID(0)
        } else {
            setIsLoading(true)
            setShowTrackingDetailsID(id)
        }
    }
    const setTrackingDetailsData = () => {
        trackingData.map(item => {
            const dateTime = requestDetails[item.status]
            item.description = dateTime ? 'At ' + moment(dateTime).format('HH:mm:ss MM/DD/YYYY') : dateTime
            return item
        })
        setTrackingData(JSON.parse(JSON.stringify(trackingData)))
        setIsLoading(false)
    }
    useEffect(() => {
        const data = getSessionData("userData") || JSON.stringify({})
        setUserData(JSON.parse(data))
    }, [])
    useEffect(() => {
        if (showTrackingDetailsID)
            getTrackingDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTrackingDetailsID])
    useEffect(() => {
        setTrackingDetailsData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestDetails])
    useEffect(() => {
        const userID = getSessionData("userID")
        if (!userID) {
            navigate("/unauthorizedUser")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const gotoTrackOrder = () => {
        navigate("/trackOrder")
    }
    return (
        <>
            <Box sx={{ flexGrow: 1, paddingLeft: 3, paddingRight: 3, paddingTop: 1, backgroundColor: "#f5f5f5" }}>
                <Grid container >
                    <Grid item xs={6} textAlign="start">
                            <Button onClick={gotoTrackOrder} sx={{ml:'-10px'}}>Track Order</Button>
                        </Grid>
                    <Grid item xs={6} textAlign="end">
                        <Button onClick={gotoNewRequest} sx={{mr:'-10px'}}>New Request</Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography className={classes.labelName}> City</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.valueStyle}>{userData.accountName},{userData.city}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography className={classes.labelName}> State</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.valueStyle}>{userData.state}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography className={classes.labelName}> Postal COde</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.valueStyle}>{userData.zip}</Typography>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

export default Home