import { Grid } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

const HeaderComponent = () => {
    const navigate = useNavigate()
    const GotoHome = () => {
        navigate('/')
    }

    return (<Grid container spacing={2} sx={{ flexGrow: 1, color: "white", background: "#f21c0c" }} display={'flex'} flexDirection="row" alignItems={'center'} >
        <Grid item xs={10} md={11} textAlign="center">
            <img onClick={GotoHome} alt="all clity towing" src="../../SVMS Logo Final-A-FULL-LRG.png" height={50} />
        </Grid>
    </Grid>)
}

export default HeaderComponent