import * as React from 'react';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function InputMultipleValuesWithChip({list,validateInput}) {
    let listById = {};
    for ( let item of list ) {
        listById[ item.id ] = item;
    }

    return (
        <Stack spacing={ 3 } >
            <Autocomplete
                multiple
                id='tags-filled'
                filterSelectedOptions={ true }
                options={ [] }
                value={ list.map( ( item ) => item.id ) }
                freeSolo
                renderTags={ ( listIds, getTagProps ) =>
                    listIds.map( ( id, index ) => (
                        <Chip
                            key={ index }
                            variant='outlined'
                            label={ listById[ id ].value }
                            sx={ {
                                color: ( theme ) => {
                                    let chipColor = '#fff';
                                    if ( typeof( listById[ id ] ) == 'object' ) {
                                        chipColor = listById[ id ].isValid
                                            ? theme.palette.common.white 
                                            : theme.palette.common.white
                                    }
                                    return chipColor;
                                },

                                backgroundColor: ( theme ) => {
                                    let chipColor = '#fff';
                                    if ( typeof( listById[ id ] ) == 'object' ) {
                                        chipColor = listById[ id ].isValid
                                            ? theme.palette.primary.main 
                                            : theme.palette.error.main
                                    }
                                    return chipColor;
                                },
                            
                                [`& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault`]: {
                                    fill: ( theme ) => theme.palette.grey[200]                
                                }
                            } }
                            { ...getTagProps( { index } ) }
                        />
                    ) )
                }
                renderInput={ ( params ) => (
                    <TextField
                        { ...params }
                       
                        label='VIN'
                        placeholder='e.g. VIN123,VIN456'
                        helperText='Enter comma separated values (no spaces)'
                    />
                ) }
                onChange={ validateInput }
            />
        </Stack>
    );
}

