import axiosInstance, { ADMIN_BASEURL } from './axiosInstance';


const useServices = () => {
    console.log("custom hook to get the data from services...")
    const GenerateHeaders = () => {
        const token = "accesstoken"
        var headers = {
            "headers": {
                "Authorization": `Bearer ${token}`
            }
        }
        return headers
    }
    const get = async (endpoint,cancelToken) => {
        try {
            //const headers = GenerateHeaders()

            const response = await axiosInstance.get(endpoint, {
                cancelToken: cancelToken, // <-- 2nd step
              }); // Add header variable for authentication
            return response
        } catch (error) {
            ServiceErrorHandling(error)
            throw error
        }

    }
    const getValidateAccount = async (endpoint) => {
        try {
            //const headers = GenerateHeaders()

            const response = await axiosInstance({url : endpoint, baseURL : ADMIN_BASEURL}); // Add header variable for authentication
            return response
        } catch (error) {
            ServiceErrorHandling(error)
            throw error
        }

    }
    const post = async (endpoint, requestBody) => {
        try {
            console.log("post method calling....")
            //const headers = GenerateHeaders()
            const response = await axiosInstance.post(endpoint, requestBody); //Add header variable for auth
            return response

        } catch (error) {
            ServiceErrorHandling(error)
            throw error
        }

    }
    const put = async (endpoint, requestBody) => {
        try {
            console.log("put method calling....")
            const headers = GenerateHeaders()
            const response = await axiosInstance.put(endpoint, requestBody, headers);
            return response

        } catch (error) {
            ServiceErrorHandling(error)
            throw error
        }

    }
    const deleteService = async (endpoint, requestBody) => {
        try {
            console.log("delete method calling....")
            const headers = GenerateHeaders()
            const response = await axiosInstance.delete(endpoint, headers);
            return response

        } catch (error) {
            ServiceErrorHandling(error)
            throw error
        }

    }
    const ServiceErrorHandling = (err) => {
        if (err.response) {
            // client received an error response (5xx, 4xx)
            if (err.response.status === 401) {
                // if unauthorized user then redirect to login page or do silent login to 
                // generate accesstoken using refreshtokens
            }
            console.log("error in api call")
        } else if (err.request) {
            // client never received a response, or request never left
            console.log("request failed and no response ")
        } else {
            // anything else
            console.log("request failed due to slow network")

        }
    }


    return { get, post, put, deleteService, getValidateAccount }



}
export default useServices