import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';

const AddressPicker = ({ lat, lng ,fetchAddressOnPointerChange}) => {
  const [center, setCenter] = useState({ lat, lng }); // default to San Francisco
  const [marker, setMarker] = useState({ lat, lng });
  const handleMapClick = ({ lat, lng }) => {
    setMarker({ lat, lng });
	fetchAddressOnPointerChange(lat, lng);
  };

  const handleMarkerDrag = (event) => {
    const lat = event.center.lat();
    const lng = event.center.lng();
    setMarker({ lat, lng });
    fetchAddressOnPointerChange(lat, lng);
  };

  const handleSearchBoxChange = ({ target }) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: target.value }, (results, status) => {
      if (status === 'OK') {
        setCenter(results[0].geometry.location);
        setMarker(results[0].geometry.location);
      }
    });
  };

  return (
    <div style={{ height: '250px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB95JaT-LA1RWVOaeBvuTU_Yf8eIsynnNc" }}
        defaultCenter={center}
        defaultZoom={18}
        options={{ gestureHandling: "greedy" }}
        onClick={handleMapClick}
        onDragEnd={handleMarkerDrag}

      >
        {marker && lat && lng && (
          <Marker
            lat={marker.lat}
            lng={marker.lng}
            draggable={true}
            onDragEnd={(event) => handleMarkerDrag(event)}
          />
        )}
        {/* <CurrentLocationMarker lat={center.lat} lng={center.lng} /> */}
      </GoogleMapReact>
    </div>
  );
};

const Marker = ({ lat, lng, draggable, onDragEnd }) => {

  return (
    <div
      lat={lat}
      lng={lng}
      draggable={true}
      style={{ cursor: true ? 'grabbing' : 'default' }}
    >
   <svg width="20" height="30">
  <rect x="9" y="0" width="2" height="23" fill="#000000"/>
  <circle cx="10" cy="7" r="7" fill="#000000"/>
</svg>  </div>
  );
};

const CurrentLocationMarker = ({ lat, lng }) => {
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      }}
      lat={lat}
      lng={lng}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="#4285F4"
        stroke="#4285F4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="5" />
      </svg>
    </div>
  );
};

export default AddressPicker;