import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Skeleton } from "@mui/material";

function TrackingComponent({ steps, isLoading, invoiceStatus, cancelReason, clearFromTrack, hookedTime }) {

  const useStyles = makeStyles(() => ({
    root: {

      "& .requested .MuiStepIcon-root": { color: "#cc0000" },
      "& .accepted .MuiStepIcon-root": { color: "#e06666" },
      "& .onroute .MuiStepIcon-root": { color: "#e69138" },
      "& .arrived .MuiStepIcon-root": { color: "#009e0f" },
      "& .disabled .MuiStepIcon-root": { color: "grey" },
    }
  }));

  const c = useStyles();

  return (
    <Box sx={{ maxWidth: 400 }}>
      {invoiceStatus !== 'Cancel' &&
        <Stepper className={c.root} orientation="vertical">
          {steps.map((step, index) => (
            <Step className={step.description ? step.class : `disabled`} active={true} key={step.label}>
              <StepLabel
                icon=" "
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {isLoading && <Skeleton />}
                {!isLoading && <Typography>{step.description}</Typography>}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      }
      {(invoiceStatus === 'Cancel' || hookedTime) &&
        <><Typography>{cancelReason}</Typography>
          <Grid item xs={12} textAlign="center">
            <Button onClick={clearFromTrack}>Clear from track</Button>
          </Grid>
        </>
      }
    </Box>
  );
}
export default TrackingComponent;
