import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
    dialogPaper : {background:"none"}
}
export default function DialogComponent({children,open,handleClose}) {
  

  return (
    <div>
    
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        classes={{paper : styles.dialogPaper}}
        PaperProps={{style : {backgroundColor:"transparent"}}}
      >
      
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{zIndex:1111,justifyContent:"flex-end"}}
            >
              <CloseIcon fontSize='large'/>
            </IconButton>
           
        
        {children}
      </Dialog>
    </div>
  );
}